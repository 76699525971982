import { defineNuxtPlugin } from '#app'
import { useLocalization, useHydrationRef } from '#imports'
import { useConfig } from '@/stores/config'

export default defineNuxtPlugin({
  name: 'app-config',
  order: 2,
  async setup () {
    const { getRegions, getCurrentRegion, currentRegion } = useConfig()
    const { setLocale, loadDynamicMessages, setMessages } = useLocalization()

    const currentLocale = useHydrationRef('current-locale', 'ru')

    const host = useRequestURL().host

    if (import.meta.client) {
      const messages = await loadDynamicMessages(currentLocale.value ?? 'ru')

      setMessages(messages)
    } else {
      try {
        const regions = await getRegions()
        currentRegion.value = getCurrentRegion(regions, host)

        let locale = currentRegion.value?.defaultLocale
        if (currentRegion.value) {
          currentLocale.value = currentRegion.value.defaultLocale

          await setLocale(currentRegion.value.defaultLocale)
        } else {
          // fallback
          await setLocale('ru')
        }

        if (locale === 'en') {
          locale = 'ru'
        }
      } catch (err) {
        await setLocale('ru')
      }
    }
  }
})
